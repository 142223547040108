import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import printJS from "print-js"

function Image() {
    const { isMobile, image, height, width, totalWidth, mainColor, infoCard } = useState(hive.state)

    const download = () => {
        const a = document.createElement('a');
        const lot = infoCard.get()
        a.href = image.get() as string;
        a.download = lot.id;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    const print = () => {
        printJS({ printable: image.get(), type: "image", style: '@page { size: Letter landscape; }' })
    }

    return <div style={{
        background: "black",
        position: "absolute",
        height: height.get() + 'px',
        width: "100vw",
        zIndex: 2,
        display: "flex",
    }}>
        <div style={{
        }}>
            <div style={{
                width: "80vw",
                height: height.get() * 0.9 + "px",
                marginTop: height.get() * 0.1 + "px",
                // backgroundColor: "red",
                display: "flex",
                flexDirection: "column"
            }}>

                {image.get() && (image.get() as string).indexOf(".pdf") === -1 &&
                    <div style={{
                        width: "100%",
                        height: "98%",
                        backgroundImage: `url(${image.get()})`,
                        backgroundPosition: "center center",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                    }}>
                    </div>
                }
                {image.get() && (image.get() as string).indexOf(".pdf") !== -1 &&
                    <div style={{
                        width: "100%",
                        height: "98%",
                        // backgroundImage: `url(${image.get()})`,
                        backgroundPosition: "center center",
                        // backgroundRepeat: "no-repeat",
                        // backgroundSize: "contain",
                    }}>
                        <embed
                            width={`${totalWidth.get() * 0.8}px`}
                            height={`${height.get()}px`}
                            src={`${window.location.href}/${image.get()}`}  ></embed>
                    </div>
                }
                {/* {!isMobile.get() &&
                    <div style={{
                        height: height.get() * 0.04,
                        // background: "red",
                        display: "flex",
                        justifyContent: "center",
                        fontSize: height.get() * 0.025,
                        color: `#${mainColor.get()}`
                    }}>
                        <div
                            onClick={() => download()}
                            style={{
                                marginRight: "10px",
                                cursor: "pointer",
                            }}>
                            Télécharger
                        </div>
                        <div
                            onClick={() => print()}
                            style={{
                                marginRight: "10px",
                                cursor: "pointer",
                            }}>
                            Imprimer
                        </div>
                    </div>
                } */}


            </div>
        </div>
        <div style={{
            width: "20vw",
            // backgroundColor: "blue",
        }}>

        </div>
    </div>
}

export default Image